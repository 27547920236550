Formulario Configuración OBJECTIVES
<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="tway-violet--text"> mdi-cog-outline </v-icon>
          CONFIGURACIÓN
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Configuración de Objetivos</h3>
            <h4 class="text-center font-weight-regular">Definir Objetivos</h4>
            <form>
              <div class="d-flex justify-space-between mt-2">
                <v-row>
                  <!-- Fecha de Inicio de Objetivos -->
                  <v-col class="col-6 mt-3">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="items[0].initialDate"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="items[0].initialDate" @input="menu = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Fecha de Termino de Objetivos -->
                  <v-col class="col-6 mt-3">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :min="inicio"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="items[0].endDate"
                          label="Fecha Término"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :min="inicio"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="items[0].endDate" @input="menu2 = false" :min="inicio"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Objetivo 1 -->
                  <v-col class="col-12 paddingFix">
                    <template class="col-12">
                      <v-row justify="center mt-6">
                        <v-expansion-panels accordion width="100%" v-for="(item, i) in items" :key="i">
                          <v-expansion-panel>
                            <v-expansion-panel-header> OBJETIVO 1 </v-expansion-panel-header>
                            <v-expansion-panel-content class="pb-10">
                              <v-row>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'20'"
                                    :minlenght="'0'"
                                    v-model="item.objName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    v-model="item.objDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12">
                                  OKR1
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr1.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr1.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta1.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta1.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta1.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta1.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="item.meta1.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>

                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr1.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR2
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr2.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr2.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>

                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta2.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta2.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta2.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta2.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="item.meta2.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>

                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr2.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR3
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr3.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="item.kr3.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta3.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta3.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta3.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="item.meta3.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="item.meta3.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="item.kr3.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                    <v-col
                                      :v-if="alert.objective1"
                                      md="12"
                                      class="text-center d-flex justify-center mx-lg-auto"
                                    >
                                      <v-alert
                                        v-model="alert.objective1"
                                        border="right"
                                        colored-border
                                        type="error"
                                        elevation="2"
                                        style="margin-bottom: unset; margin-top: 10px"
                                      >
                                        {{ messageError.objective1 }}
                                      </v-alert>
                                    </v-col>
                                    <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                                      <v-btn
                                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                                        outlined
                                        color="indigo lighten-2"
                                        @click="addObjective1()"
                                      >
                                        <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                                        Guardar Objetivo 1
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- Objetivo 2 !-->
                  <v-col class="col-12 paddingFix">
                    <template class="col-12">
                      <v-row justify="center mt-6">
                        <v-expansion-panels accordion width="100%" v-for="(it, index) in items2" :key="index">
                          <v-expansion-panel>
                            <v-expansion-panel-header> OBJETIVO 2 </v-expansion-panel-header>
                            <v-expansion-panel-content class="pb-10">
                              <v-row>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'20'"
                                    :minlenght="'0'"
                                    v-model="it.objName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    v-model="it.objDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12">
                                  OKR1
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr1.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr1.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta1.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta1.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta1.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta1.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="it.meta1.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr1.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR2
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr2.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr2.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta2.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta2.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta2.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta2.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="it.meta2.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr2.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR3
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr3.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="it.kr3.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta3.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta3.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta3.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="it.meta3.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="it.meta3.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="it.kr3.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                    <v-col
                                      :v-if="alert.objective2"
                                      md="12"
                                      class="text-center d-flex justify-center mx-lg-auto"
                                    >
                                      <v-alert
                                        v-model="alert.objective2"
                                        border="right"
                                        colored-border
                                        type="error"
                                        elevation="2"
                                        style="margin-bottom: unset; margin-top: 10px"
                                      >
                                        {{ messageError.objective2 }}
                                      </v-alert>
                                    </v-col>
                                    <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                                      <v-btn
                                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                                        outlined
                                        color="indigo lighten-2"
                                        @click="addObjective2()"
                                      >
                                        <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                                        Guardar Objetivo 2
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- Objetivo 3 -->
                  <v-col class="col-12 paddingFix">
                    <template class="col-12">
                      <v-row justify="center mt-6">
                        <v-expansion-panels accordion width="100%" v-for="(itm, ind) in items3" :key="ind">
                          <v-expansion-panel>
                            <v-expansion-panel-header> OBJETIVO 3 </v-expansion-panel-header>
                            <v-expansion-panel-content class="pb-10">
                              <v-row>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'20'"
                                    :minlenght="'0'"
                                    v-model="itm.objName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción Objetivo"
                                    type="text"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    v-model="itm.objDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12">
                                  OKR1
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr1.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr1.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta1.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta1.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta1.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta1.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="itm.meta1.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr1.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR2
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr2.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr2.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta2.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta2.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta2.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta2.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="itm.meta2.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr2.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col class="col-12 mt-8">
                                  OKR3
                                  <v-divider class="divisor"></v-divider>
                                </v-col>
                                <v-col class="col-3">
                                  <v-text-field
                                    label="Título OKR"
                                    :hide-details="true"
                                    :maxlength="'35'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr3.krName"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-9">
                                  <v-text-field
                                    label="Descripción OKR"
                                    :hide-details="true"
                                    :maxlength="'100'"
                                    :minlenght="'0'"
                                    type="text"
                                    v-model="itm.kr3.krDescription"
                                    outlined
                                  />
                                </v-col>
                                <v-col class="col-12 px-12">
                                  <v-row>
                                    <v-col class="col-12 mb-3">
                                      <span class="subtitulo">Metas</span>
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Título Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta3.name"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Descripción Meta"
                                        type="text"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta3.description"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-3 columna-meta">
                                      <v-text-field
                                        label="Cantidad línea base"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta3.baseline"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-text-field
                                        label="Cantidad"
                                        type="number"
                                        :hide-details="true"
                                        :maxlength="'35'"
                                        :minlenght="'0'"
                                        v-model="itm.meta3.quantity"
                                        outlined
                                      />
                                    </v-col>
                                    <v-col class="col-2 columna-meta">
                                      <v-select
                                        :items="combos.metaUnit"
                                        item-value="id"
                                        item-text="name"
                                        label="Unidad Medida"
                                        v-model="itm.meta3.unit"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <v-col class="col-12">
                                      <template>
                                        <v-row justify="center">
                                          <v-expansion-panels accordion width="100%">
                                            <v-expansion-panel class="mt-4">
                                              <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                              <v-expansion-panel-content class="pb-4">
                                                <v-row>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 1"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative1.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative1.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 2"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative2.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative2.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-3">
                                                    <v-text-field
                                                      label="Título Iniciativa 3"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'35'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative3.name"
                                                      outlined
                                                    />
                                                  </v-col>
                                                  <v-col class="col-9">
                                                    <v-text-field
                                                      label="Descripción"
                                                      type="text"
                                                      :hide-details="true"
                                                      :maxlength="'100'"
                                                      :minlenght="'0'"
                                                      v-model="itm.kr3.initiative3.description"
                                                      outlined
                                                    />
                                                  </v-col>
                                                </v-row>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-row>
                                      </template>
                                    </v-col>
                                    <v-col
                                      :v-if="alert.objective3"
                                      md="12"
                                      class="text-center d-flex justify-center mx-lg-auto"
                                    >
                                      <v-alert
                                        v-model="alert.objective3"
                                        border="right"
                                        colored-border
                                        type="error"
                                        elevation="2"
                                        style="margin-bottom: unset; margin-top: 10px"
                                      >
                                        {{ messageError.objective3 }}
                                      </v-alert>
                                    </v-col>
                                    <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                                      <v-btn
                                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                                        outlined
                                        color="indigo lighten-2"
                                        @click="addObjective3()"
                                      >
                                        <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                                        Guardar Objetivo 3
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- Actualización de graficos -->
                  <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                    <v-btn
                      outlined
                      color="indigo lighten-2"
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                      @click="closeConfigurationDialog()"
                    >
                      <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </form>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import objectuvesService from "@/services/pmo/objectives/objectivesService";

export default {
  data: () => {
    return {
      dialog: false,
      inicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      termino: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      medida: ["%", "Facebook Likes", "Leads", "Dolares", "Pesos", "UF"],
      items: [
        {
          enterpriseId: "",
          projectId: "",
          objIndex: "",
          objName: "",
          objDescription: "",
          initialDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          kr1: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr2: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr3: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          meta1: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta2: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta3: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
        },
      ],
      items2: [
        {
          enterpriseId: "",
          projectId: "",
          objIndex: "",
          objName: "",
          objDescription: "",
          initialDate: "",
          endDate: "",
          kr1: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr2: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr3: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          meta1: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta2: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta3: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
        },
      ],
      items3: [
        {
          enterpriseId: "",
          projectId: "",
          objIndex: "",
          objName: "",
          objDescription: "",
          initialDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
          kr1: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr2: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          kr3: {
            krName: "",
            krDescription: "",
            initiative1: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative2: {
              name: "",
              description: "",
              progress: 0,
            },
            initiative3: {
              name: "",
              description: "",
              progress: 0,
            },
          },
          meta1: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta2: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
          meta3: {
            name: "",
            description: "",
            quantity: 0,
            baseline: "",
            unit: "",
          },
        },
      ],
      combos: {
        metaUnit: [],
      },
      alert: {
        objective1: false,
        objective2: false,
        objective3: false,
      },
      messageError: {
        objective1: "",
        objective2: "",
        objective3: "",
      },
    };
  },
  methods: {
    clearForm() {},
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadObjectiveCharts");
      this.addObjective1();
      this.addObjective2();
      this.addObjective3();
    },
    addObjective1() {
      this.items[0].enterpriseId = this.project.enterpriseId;
      this.items[0].projectId = this.project.projectId;
      objectuvesService
        .addObjective(this.items[0])
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.objective1 = true;
          this.messageError.objective1 = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.objective1 = false), 4000);
        });
    },
    addObjective2() {
      this.items2[0].enterpriseId = this.project.enterpriseId;
      this.items2[0].projectId = this.project.projectId;
      this.items2[0].initialDate = this.items[0].initialDate;
      this.items2[0].endDate = this.items[0].endDate;
      objectuvesService
        .addObjective(this.items2[0])
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.objective2 = true;
          this.messageError.objective2 = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.objective2 = false), 4000);
        });
    },
    addObjective3() {
      this.items3[0].enterpriseId = this.project.enterpriseId;
      this.items3[0].projectId = this.project.projectId;
      this.items3[0].initialDate = this.items[0].initialDate;
      this.items3[0].endDate = this.items[0].endDate;
      objectuvesService
        .addObjective(this.items3[0])
        .catch((err) => {
          console.dir(err);
          let message = err.response.data.message;
          this.alert.objective3 = true;
          this.messageError.objective3 = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.objective3 = false), 4000);
        });
    },
    getOkrList() {
      objectuvesService
        .getObjetiveInformation(this.project)
        .then((data) => {
          console.log("data", data);
          this.items = [
            {
              enterpriseId: this.project.enterpriseId,
              projectId: this.project.projectId,
              objName: data[0].keyResult[0]?.objetiveName,
              objDescription: data[0].keyResult[0]?.objetiveDescription,
              objIndex: data[0].keyResult[0]?.objectiveIndex,
              initialDate: data[0].keyResult[0]?.initialDate,
              endDate: data[0].keyResult[0]?.endDate,
              kr1: {
                krName: data[0].keyResult[0]?.krName,
                krDescription: data[0].keyResult[0]?.krDescription,
                initiative1: {
                  name: data[0].keyResult[0]?.initiativeName && data[0].keyResult[0]?.initiativeName[0],
                  description:
                    data[0].keyResult[0]?.initiativeDescription && data[0].keyResult[0]?.initiativeDescription[0],
                  progress: data[0].keyResult[0]?.initiativeProgress && data[0].keyResult[0]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[0].keyResult[0]?.initiativeName && data[0].keyResult[0]?.initiativeName[1],
                  description:
                    data[0].keyResult[0]?.initiativeDescription && data[0].keyResult[0]?.initiativeDescription[1],
                  progress: data[0].keyResult[0]?.initiativeProgress && data[0].keyResult[0]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[0].keyResult[0]?.initiativeName && data[0].keyResult[0].initiativeName[2],
                  description:
                    data[0].keyResult[0]?.initiativeDescription && data[0].keyResult[0].initiativeDescription[2],
                  progress: data[0].keyResult[0]?.initiativeProgress && data[0].keyResult[0].initiativeProgress[2],
                },
              },
              kr2: {
                krName: data[0].keyResult[1]?.krName,
                krDescription: data[0].keyResult[1]?.krDescription,
                initiative1: {
                  name: data[0].keyResult[1]?.initiativeName && data[0].keyResult[1]?.initiativeName[0],
                  description:
                    data[0].keyResult[1]?.initiativeDescription && data[0].keyResult[1]?.initiativeDescription[0],
                  progress: data[0].keyResult[1]?.initiativeProgress && data[0].keyResult[1]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[0].keyResult[1]?.initiativeName && data[0].keyResult[1]?.initiativeName[1],
                  description:
                    data[0].keyResult[1]?.initiativeDescription && data[0].keyResult[1]?.initiativeDescription[1],
                  progress: data[0].keyResult[1]?.initiativeProgress && data[0].keyResult[1]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[0].keyResult[1]?.initiativeName && data[0].keyResult[1]?.initiativeName[2],
                  description:
                    data[0].keyResult[1]?.initiativeDescription && data[0].keyResult[1]?.initiativeDescription[2],
                  progress: data[0].keyResult[1]?.initiativeProgress && data[0].keyResult[1]?.initiativeProgress[2],
                },
              },
              kr3: {
                krName: data[0].keyResult[2]?.krName,
                krDescription: data[0].keyResult[2]?.krDescription,
                initiative1: {
                  name: data[0].keyResult[2]?.initiativeName && data[0].keyResult[2]?.initiativeName[0],
                  description:
                    data[0].keyResult[2]?.initiativeDescription && data[0].keyResult[2]?.initiativeDescription[0],
                  progress: data[0].keyResult[2]?.initiativeProgress && data[0].keyResult[2]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[0].keyResult[2]?.initiativeName && data[0].keyResult[2]?.initiativeName[1],
                  description:
                    data[0].keyResult[2]?.initiativeDescription && data[0].keyResult[2]?.initiativeDescription[1],
                  progress: data[0].keyResult[2]?.initiativeProgress && data[0].keyResult[2]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[0].keyResult[2]?.initiativeName && data[0].keyResult[2]?.initiativeName[2],
                  description:
                    data[0].keyResult[2]?.initiativeDescription && data[0].keyResult[2]?.initiativeDescription[2],
                  progress: data[0].keyResult[2]?.initiativeProgress && data[0].keyResult[2]?.initiativeProgress[2],
                },
              },
              meta1: {
                name: data[0].keyResult[0]?.metaName,
                description: data[0].keyResult[0]?.metaDescription,
                quantity: data[0].keyResult[0]?.metaQuantity,
                baseline: data[0].keyResult[0]?.metaBaseline,
                unit: data[0].keyResult[0]?.metaUnit,
              },
              meta2: {
                name: data[0].keyResult[1]?.metaName,
                description: data[0].keyResult[1]?.metaDescription,
                quantity: data[0].keyResult[1]?.metaQuantity,
                baseline: data[0].keyResult[1]?.metaBaseline,
                unit: data[0].keyResult[1]?.metaUnit,
              },
              meta3: {
                name: data[0].keyResult[2]?.metaName,
                description: data[0].keyResult[2]?.metaDescription,
                quantity: data[0].keyResult[2]?.metaQuantity,
                baseline: data[0].keyResult[2]?.metaBaseline,
                unit: data[0].keyResult[2]?.metaUnit,
              },
            },
          ];
          this.items2 = [
            {
              enterpriseId: this.project.enterpriseId,
              projectId: this.project.projectId,
              objName: data[1].keyResult[0]?.objetiveName,
              objDescription: data[1].keyResult[0]?.objetiveDescription,
              objIndex: data[1].keyResult[0]?.objectiveIndex,
              initialDate: data[1].keyResult[0]?.initialDate,
              endDate: data[1].keyResult[0]?.endDate,
              kr1: {
                krName: data[1].keyResult[0]?.krName,
                krDescription: data[1].keyResult[0]?.krDescription,
                initiative1: {
                  name: data[1].keyResult[0]?.initiativeName && data[1].keyResult[0]?.initiativeName[0],
                  description:
                    data[1].keyResult[0]?.initiativeDescription && data[1].keyResult[0]?.initiativeDescription[0],
                  progress: data[1].keyResult[0]?.initiativeProgress && data[1].keyResult[0]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[1].keyResult[0]?.initiativeName && data[1].keyResult[0]?.initiativeName[1],
                  description:
                    data[1].keyResult[0]?.initiativeDescription && data[1].keyResult[0]?.initiativeDescription[1],
                  progress: data[1].keyResult[0]?.initiativeProgress && data[1].keyResult[0]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[1].keyResult[0]?.initiativeName && data[1].keyResult[0]?.initiativeName[2],
                  description:
                    data[1].keyResult[0]?.initiativeDescription && data[1].keyResult[0]?.initiativeDescription[2],
                  progress: data[1].keyResult[0]?.initiativeProgress && data[1].keyResult[0]?.initiativeProgress[2],
                },
              },
              kr2: {
                krName: data[1].keyResult[1]?.krName,
                krDescription: data[1].keyResult[1]?.krDescription,
                initiative1: {
                  name: data[1].keyResult[1]?.initiativeName && data[1].keyResult[1]?.initiativeName[0],
                  description:
                    data[1].keyResult[1]?.initiativeDescription && data[1].keyResult[1]?.initiativeDescription[0],
                  progress: data[1].keyResult[1]?.initiativeProgress && data[1].keyResult[1]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[1].keyResult[1]?.initiativeName && data[1].keyResult[1]?.initiativeName[1],
                  description:
                    data[1].keyResult[1]?.initiativeDescription && data[1].keyResult[1]?.initiativeDescription[1],
                  progress: data[1].keyResult[1]?.initiativeProgress && data[1].keyResult[1]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[1].keyResult[1]?.initiativeName && data[1].keyResult[1]?.initiativeName[2],
                  description:
                    data[1].keyResult[1]?.initiativeDescription && data[1].keyResult[1]?.initiativeDescription[2],
                  progress: data[1].keyResult[1]?.initiativeProgress && data[1].keyResult[1]?.initiativeProgress[2],
                },
              },
              kr3: {
                krName: data[1].keyResult[2]?.krName,
                krDescription: data[1].keyResult[2]?.krDescription,
                initiative1: {
                  name: data[1].keyResult[2]?.initiativeName && data[1].keyResult[2]?.initiativeName[0],
                  description:
                    data[1].keyResult[2]?.initiativeDescription && data[1].keyResult[2]?.initiativeDescription[0],
                  progress: data[1].keyResult[2]?.initiativeProgress && data[1].keyResult[2]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[1].keyResult[2]?.initiativeName && data[1].keyResult[2]?.initiativeName[1],
                  description:
                    data[1].keyResult[2]?.initiativeDescription && data[1].keyResult[2]?.initiativeDescription[1],
                  progress: data[1].keyResult[2]?.initiativeProgress && data[1].keyResult[2]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[1].keyResult[2]?.initiativeName && data[1].keyResult[2]?.initiativeName[2],
                  description:
                    data[1].keyResult[2]?.initiativeDescription && data[1].keyResult[2]?.initiativeDescription[2],
                  progress: data[1].keyResult[2]?.initiativeProgress && data[1].keyResult[2]?.initiativeProgress[2],
                },
              },
              meta1: {
                name: data[1].keyResult[0]?.metaName,
                description: data[1].keyResult[0]?.metaDescription,
                quantity: data[1].keyResult[0]?.metaQuantity,
                baseline: data[1].keyResult[0]?.metaBaseline,
                unit: data[1].keyResult[0]?.metaUnit,
              },
              meta2: {
                name: data[1].keyResult[1]?.metaName,
                description: data[1].keyResult[1]?.metaDescription,
                quantity: data[1].keyResult[1]?.metaQuantity,
                baseline: data[1].keyResult[1]?.metaBaseline,
                unit: data[1].keyResult[1]?.metaUnit,
              },
              meta3: {
                name: data[1].keyResult[2]?.metaName,
                description: data[1].keyResult[2]?.metaDescription,
                quantity: data[1].keyResult[2]?.metaQuantity,
                baseline: data[1].keyResult[2]?.metaBaseline,
                unit: data[1].keyResult[2]?.metaUnit,
              },
            },
          ];
          this.items3 = [
            {
              enterpriseId: this.project.enterpriseId,
              projectId: this.project.projectId,
              objName: data[2].keyResult[0]?.objetiveName,
              objDescription: data[2].keyResult[0]?.objetiveDescription,
              objIndex: data[2].keyResult[0]?.objectiveIndex,
              initialDate: data[2].keyResult[0]?.initialDate,
              endDate: data[2].keyResult[0]?.endDate,
              kr1: {
                krName: data[2].keyResult[0]?.krName,
                krDescription: data[2].keyResult[0]?.krDescription,
                initiative1: {
                  name: data[2].keyResult[0]?.initiativeName && data[2].keyResult[0]?.initiativeName[0],
                  description:
                    data[2].keyResult[0]?.initiativeDescription && data[2].keyResult[0]?.initiativeDescription[0],
                  progress: data[2].keyResult[0]?.initiativeProgress && data[2].keyResult[0]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[2].keyResult[0]?.initiativeName && data[2].keyResult[0]?.initiativeName[1],
                  description:
                    data[2].keyResult[0]?.initiativeDescription && data[2].keyResult[0]?.initiativeDescription[1],
                  progress: data[2].keyResult[0]?.initiativeProgress && data[2].keyResult[0]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[2].keyResult[0]?.initiativeName && data[2].keyResult[0]?.initiativeName[2],
                  description:
                    data[2].keyResult[0]?.initiativeDescription && data[2].keyResult[0]?.initiativeDescription[2],
                  progress: data[2].keyResult[0]?.initiativeProgress && data[2].keyResult[0]?.initiativeProgress[2],
                },
              },
              kr2: {
                krName: data[2].keyResult[1]?.krName,
                krDescription: data[2].keyResult[1]?.krDescription,
                initiative1: {
                  name: data[2].keyResult[1]?.initiativeName && data[2].keyResult[1]?.initiativeName[0],
                  description:
                    data[2].keyResult[1]?.initiativeDescription && data[2].keyResult[1]?.initiativeDescription[0],
                  progress: data[2].keyResult[1]?.initiativeProgress && data[2].keyResult[1]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[2].keyResult[1]?.initiativeName && data[2].keyResult[1]?.initiativeName[1],
                  description:
                    data[2].keyResult[1]?.initiativeDescription && data[2].keyResult[1]?.initiativeDescription[1],
                  progress: data[2].keyResult[1]?.initiativeProgress && data[2].keyResult[1]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[2].keyResult[1]?.initiativeName && data[2].keyResult[1]?.initiativeName[2],
                  description:
                    data[2].keyResult[1]?.initiativeDescription && data[2].keyResult[1]?.initiativeDescription[2],
                  progress: data[2].keyResult[1]?.initiativeProgress && data[2].keyResult[1]?.initiativeProgress[2],
                },
              },
              kr3: {
                krName: data[2].keyResult[2]?.krName,
                krDescription: data[2].keyResult[2]?.krDescription,
                initiative1: {
                  name: data[2].keyResult[2]?.initiativeName && data[2].keyResult[2]?.initiativeName[0],
                  description:
                    data[2].keyResult[2]?.initiativeDescription && data[2].keyResult[2]?.initiativeDescription[0],
                  progress: data[2].keyResult[2]?.initiativeProgress && data[2].keyResult[2]?.initiativeProgress[0],
                },
                initiative2: {
                  name: data[2].keyResult[2]?.initiativeName && data[2].keyResult[2]?.initiativeName[1],
                  description:
                    data[2].keyResult[2]?.initiativeDescription && data[2].keyResult[2]?.initiativeDescription[1],
                  progress: data[2].keyResult[2]?.initiativeProgress && data[2].keyResult[2]?.initiativeProgress[1],
                },
                initiative3: {
                  name: data[2].keyResult[2]?.initiativeName && data[2].keyResult[2]?.initiativeName[2],
                  description:
                    data[2].keyResult[2]?.initiativeDescription && data[2].keyResult[2]?.initiativeDescription[2],
                  progress: data[2].keyResult[2]?.initiativeProgress && data[2].keyResult[2]?.initiativeProgress[2],
                },
              },
              meta1: {
                name: data[2].keyResult[0]?.metaName,
                description: data[2].keyResult[0]?.metaDescription,
                quantity: data[2].keyResult[0]?.metaQuantity,
                baseline: data[2].keyResult[0]?.metaBaseline,
                unit: data[2].keyResult[0]?.metaUnit,
              },
              meta2: {
                name: data[2].keyResult[1]?.metaName,
                description: data[2].keyResult[1]?.metaDescription,
                quantity: data[2].keyResult[1]?.metaQuantity,
                baseline: data[2].keyResult[1]?.metaBaseline,
                unit: data[2].keyResult[1]?.metaUnit,
              },
              meta3: {
                name: data[2].keyResult[2]?.metaName,
                description: data[2].keyResult[2]?.metaDescription,
                quantity: data[2].keyResult[2]?.metaQuantity,
                baseline: data[2].keyResult[2]?.metaBaseline,
                unit: data[2].keyResult[2]?.metaUnit,
              },
            },
          ];
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getOkrList();
    objectuvesService
      .getMetaUnit()
      .then((data) => {
        this.combos.metaUnit = data;
      })
      .catch((err) => {
        console.dir(err);
      });
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.divisor {
  margin-top: 11px !important;
  width: calc(96% - 100px);
  float: right;
}
.subtitulo {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}
.columna-meta.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.modalSystem .v-expansion-panels--accordion > .v-expansion-panel {
  border-left: 4px solid #4f228d;
}
.modalSystem .v-expansion-panel > .v-expansion-panel-header {
  font-weight: bold;
}
.paddingFix {
  padding: 0 !important;
}
</style>
