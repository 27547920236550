<template>
  <div>
    <div class="mx-4 mt-1 pt-2 tway-violet--text" style="text-align: center">
      <p>{{ title }}</p>
    </div>
    <apexcharts type="radialBar" height="290" :options="options" :series="series"></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "LineBar",
  props: {
    title: {
      type: String,
      default: null,
    },
    series: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {};
  },
  methods: {
    updateChart() {
      this.chartOptions = {
        xaxis: {
          categories: this.labels,
        },
      };
      this.series.push({
        name: "Presupuesto",
        data: this.datasets[0].data,
      });
    },
  },
  mounted() {
    // this.updateChart();
  },
};
</script>
