<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>

      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="closeConfigurationDialog()" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Seguimiento de Objetivos</h3>
            <h4 class="text-center font-weight-regular mb-5">Ingresa nuevo Seguimiento</h4>
            <template>
              <v-row justify="center">
                <v-expansion-panels accordion width="100%">
                  <v-expansion-panel v-for="(obj, i) in objItem" :key="i">
                    <v-expansion-panel-header> OBJETIVO {{ i + 1 }} </v-expansion-panel-header>
                    <v-expansion-panel-content class="table">
                      <v-row
                        v-for="(kr, index) in obj.keyResult"
                        :key="index"
                        :class="kr.isVisible ? activeClas : hidden"
                      >
                        <!-- KR -->
                        <v-col class="col-12">
                          OKR {{ index + 1 }}
                          <v-divider class="divisor"></v-divider>
                        </v-col>
                        <v-col class="col-3">
                          <v-text-field
                            label="Título KR"
                            :hide-details="true"
                            type="text"
                            v-model="kr.name"
                            readonly
                            color="purple"
                          />
                        </v-col>
                        <v-col class="col-9">
                          <v-text-field
                            label="Descripción KR"
                            type="text"
                            v-model="kr.description"
                            readonly
                            color="purple"
                          />
                        </v-col>

                        <!-- META E INICIATIVAS -->
                        <v-col class="col-12 px-12">
                          <v-row>
                            <!-- META -->
                            <v-col class="col-12 mb-3">
                              <span class="subtitulo">Metas</span>
                            </v-col>
                            <v-col class="col-2 columna-meta">
                              <v-text-field
                                label="Título Meta"
                                type="text"
                                :hide-details="true"
                                v-model="kr.meta.name"
                                readonly
                                color="purple"
                              />
                            </v-col>
                            <v-col class="col-3 columna-meta">
                              <v-text-field
                                label="Descripción Meta"
                                type="text"
                                :hide-details="true"
                                v-model="kr.meta.description"
                                readonly
                                color="purple"
                              />
                            </v-col>
                            <v-col class="col-2 columna-meta">
                              <v-text-field
                                label="Cantidad línea base"
                                type="text"
                                :hide-details="true"
                                v-model="kr.meta.baseline"
                                readonly
                                color="purple"
                              />
                            </v-col>
                            <v-col class="col-1 columna-meta">
                              <v-text-field
                                label="Cantidad"
                                type="text"
                                :hide-details="true"
                                v-model="kr.meta.quantity"
                                readonly
                                color="purple"
                              />
                            </v-col>
                            <v-col class="col-2 columna-meta">
                              <v-text-field
                                label="Avance"
                                type="text"
                                :hide-details="true"
                                :maxlength="'15'"
                                :minlenght="'0'"
                                v-model="kr.meta.progress"
                                outlined
                              />
                            </v-col>
                            <v-col class="col-2 columna-meta">
                              <v-text-field
                                label="Unidad"
                                type="text"
                                :hide-details="true"
                                :maxlength="'15'"
                                :minlenght="'0'"
                                v-model="kr.meta.unitName"
                                readonly
                              />
                            </v-col>
                            <!-- INICIATIVAS -->
                            <v-col class="col-12">
                              <template>
                                <v-row justify="center">
                                  <v-expansion-panels accordion width="100%">
                                    <v-expansion-panel class="mt-4">
                                      <v-expansion-panel-header> INICIATIVAS </v-expansion-panel-header>
                                      <v-expansion-panel-content class="pb-4">
                                        <v-row v-for="(initiative, indx) in kr.initiativeDTOList" :key="indx">
                                          <v-col class="col-3">
                                            <v-text-field
                                              label="Título Iniciativa 1"
                                              type="text"
                                              :hide-details="true"
                                              v-model="initiative.name"
                                              readonly
                                              color="purple"
                                            />
                                          </v-col>
                                          <v-col class="col-7">
                                            <v-text-field
                                              label="Descripción"
                                              type="text"
                                              :hide-details="true"
                                              v-model="initiative.description"
                                              readonly
                                              color="purple"
                                            />
                                          </v-col>
                                          <v-col class="col-2">
                                            <v-text-field
                                              label="Avance"
                                              type="text"
                                              :hide-details="true"
                                              :maxlength="'15'"
                                              :minlenght="'0'"
                                              v-model="initiative.progress"
                                              outlined
                                            />
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </v-row>
                              </template>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!-- Boton Guardar -->
                      <v-row>
                        <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                          <v-btn
                            class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                            outlined
                            color="indigo lighten-2"
                            @click="saveObjective(obj)"
                          >
                            <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                            Guardar Objetivo {{ i + 1 }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <v-row>
                <!-- Actualización de graficos -->
                <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">close</v-icon>
                    Cerrar
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import objectivesService from "../../../services/pmo/objectives/objectivesService";

export default {
  data() {
    return {
      dialog: false,
      avance: ["%", "Facebook Likes", "Leads", "Dolares", "Pesos", "UF"],
      avanceIn: ["%", "Facebook Likes", "Leads", "Dolares", "Pesos", "UF"],
      item: [
        {
          nombreOkr: "",
          descripcionOkr: "",
          lineaBase: "",
          iniciativa: "",
          descripcionIn: "",
          avance: "",
          avanceIn: "",
        },
      ],
      nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
      isVisible: false,
      activeClass: "is-visible",
      isShow: true,
      opened: 0,
      objItem: [],
    };
  },
  methods: {
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadObjectiveCharts");
    },
    saveItem(index) {
      index.isVisible = !index.isVisible;
    },
    getOkrList() {
      objectivesService
        .getOkrList(this.project)
        .then((data) => {
          this.objItem = data;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    saveObjective(obj) {
      const request = {
        objectiveIndex: obj.keyResult[0]?.idObjective,
        metaKr1Progress: obj.keyResult[0]?.meta.progress,
        metaKr2Progress: obj.keyResult[1]?.meta.progress,
        metaKr3Progress: obj.keyResult[2]?.meta.progress,

        initiative1Kr1Progress: obj.keyResult[0]?.initiativeDTOList[0]?.progress,
        initiative2Kr1Progress: obj.keyResult[0]?.initiativeDTOList[1]?.progress,
        initiative3Kr1Progress: obj.keyResult[0]?.initiativeDTOList[2]?.progress,

        initiative1Kr2Progress: obj.keyResult[1]?.initiativeDTOList[0]?.progress,
        initiative2Kr2Progress: obj.keyResult[1]?.initiativeDTOList[1]?.progress,
        initiative3Kr2Progress: obj.keyResult[1]?.initiativeDTOList[2]?.progress,

        initiative1Kr3Progress: obj.keyResult[2]?.initiativeDTOList[0]?.progress,
        initiative2Kr3Progress: obj.keyResult[2]?.initiativeDTOList[1]?.progress,
        initiative3Kr3Progress: obj.keyResult[2]?.initiativeDTOList[2]?.progress,

        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
      };
      console.log("datos obj", obj);
      console.log("datos request", request);
      objectivesService
        .saveObjective(request)
        .then(() => this.getOkrList())
        .catch((err) => {
          console.dir(err);
        });
    },
    removeKr(kr) {
      const idKr = kr.idKr;
      objectivesService
        .deleteKeyResult(idKr)
        .then(() => this.getOkrList())
        .catch((err) => console.dir(err));
    },
    removeInitiative(initiative) {
      const params = {
        idKr: initiative.idKr,
        initiativeName: initiative.name,
      };
      objectivesService
        .deleteInitiative(params.idKr, params.initiativeName)
        .then(() => this.getOkrList())
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;
    this.getOkrList();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
</style>
