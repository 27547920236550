<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <configuration class="" @reloadObjectiveCharts="reloadCharts"></configuration>
      <seguimiento class="ml-5" @reloadObjectiveCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Objetivos y Resultados Claves</h3>

    <div class="resumen">
      <div class="tway-violet--text">
        <span style="font-size: 18px">Resumen</span>
      </div>
      <div class="resumen-bar">
        <VueApexCharts
          :series="summaryObjectives.series"
          :options="summaryObjectives.options"
          type="bar"
          class="full"
          height="150"
        />
      </div>
    </div>
    <!-- OBJECTIVES -->
    <div class="seguimiento" v-for="(objList, iObj) in dataQuantity" :key="iObj">
      <div style="position: absolute; padding: 5px">
        {{ objList.objective }}
      </div>
      <div class="seguimiento-periodo" v-for="(objetive, i) in objList.listKr" :key="i">
        <template v-if="i == 0">
          <div class="mt-3 graphic-align">
            <span class="tway-violet--text ml-2 mt-5"
              >{{ objetive.description }}
              <ToolTips
                width="300px"
                description="OKR: Resultados Claves que reflejan cuán cerca se está de los Objetivos de Negocio y cómo las iniciativas ejecutadas aportan al éxito de ello."
              />
            </span>
          </div>
          <div class="seguimiento-text">
            <div>
              <span>Línea base: </span>
              <span> {{ objetive.metaInformation[0] + " " + objetive.metaInformation[3] }}</span>
            </div>
            <div>
              <span>Meta: </span>
              <span> {{ objetive.metaInformation[1] + " " + objetive.metaInformation[3] }}</span>
            </div>
            <div>
              <span>Avance: </span>
              <span> {{ objetive.metaInformation[2] + " " + objetive.metaInformation[3] }}</span>
            </div>
          </div>
          <CircleChart
            :series="objetive.series"
            :options="
              iObj === 0 ? usersQuantity1.options : iObj === 1 ? usersQuantity2.options : usersQuantity3.options
            "
          />
        </template>
        <template v-else>
          <div class="mt-3 graphic-align">
            <span class="tway-violet--text ml-2 mt-5">{{ objetive.description }} </span>
          </div>
          <div class="seguimiento-text">
            <div>
              <span>Línea base: </span>
              <span>
                {{ objetive.metaInformation[0] + " " + objetive.metaInformation[3] }}
              </span>
            </div>
            <div>
              <span>Meta: </span>
              <span>
                {{ objetive.metaInformation[1] + " " + objetive.metaInformation[3] }}
              </span>
            </div>
            <div>
              <span>Avance: </span>
              <span>
                {{ objetive.metaInformation[2] + " " + objetive.metaInformation[3] }}
              </span>
            </div>
          </div>
          <CircleChart
            :series="objetive.series"
            :options="
              iObj === 0 ? usersQuantity1.options : iObj === 1 ? usersQuantity2.options : usersQuantity3.options
            "
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Configuration from "../../components/pmo/objectives/configuration.vue";
import Seguimiento from "../../components/pmo/objectives/seguimiento.vue";
import ObjectivesService from "@/services/pmo/objectives/objectivesService";
// import StackedBars100 from "../../components/charts/pmo/apex/bar/StackedBars100";
import CircleChart from "../../components/charts/pmo/apex/radialbar/CircleChart";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";
//import { runInThisContext } from "vm";

export default {
  name: "Objectives",
  components: {
    Configuration,
    Seguimiento,
    // StackedBars100,
    CircleChart,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      cants: [],
      charts: {
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      serieStacked: [],
      summaryObjectives: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 0,
              columnWidth: "100%",
              barHeight: "100%",
              distributed: false,
            },
          },
          stroke: {
            lineCap: "round",
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: [""],
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          colors: ["#16C62E", "#4A148C", "#9f9f9f", "#AEEA00", "#D7263D"],
          fill: {
            opacity: 1,
          },
          legend: {
            position: "left",
            horizontalAlign: "left",
            width: 350,
            offsetX: 0,
            offsetY: 0,
          },
          dataLabels: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
      dataQuantity: {
        objetivo1: [],
        objetivo2: [],
        objetivo3: [],
      },
      usersQuantity1: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          // Verde Claro:'#16C62E' - Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Gris: '#7F7F7F'
          colors: ["#16C62E", "#16C62E80", "#16C62E60", "#16C62E40", "#16C62E20"],
          labels: ["Iniciativa 1", "Iniciativa 2", "Iniciativa 3"],
          legend: {
            show: true,
            floating: true,
            fontSize: "10px",
            position: "left",
            offsetX: 0,
            offsetY: 0,
            labels: {
              useSeriesColors: false,
              colors: ["#B2B2B2", "#7F7F7F", "#B2B2B2", "#7F7F7F"],
            },
            markers: {
              size: 0,
            },
            formatter: function (seriesName, opts) {
              return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%";
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: true,
                },
              },
            },
          ],
          stroke: {
            lineCap: "round",
          },
        },
      },
      usersQuantity2: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          // Verde Claro:'#16C62E' - Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Gris: '#7F7F7F'
          colors: ["#4A148C", "#4A148C80", "#4A148C60", "#4A148C40", "#4A148C20"],
          labels: ["Iniciativa 1", "Iniciativa 2", "Iniciativa 3"],
          legend: {
            show: true,
            floating: true,
            fontSize: "10px",
            position: "left",
            offsetX: 0,
            offsetY: 0,
            labels: {
              useSeriesColors: false,
            },
            markers: {
              size: 0,
            },
            formatter: function (seriesName, opts) {
              return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%";
            },
            itemMargin: {
              vertical: 3,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          stroke: {
            lineCap: "round",
          },
        },
      },
      usersQuantity3: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          //colors: ["#B2B2B2", "#7F7F7F"],
          colors: ["#666666", "#8c8c8c", "#b5b5b5"],
          labels: ["Iniciativa 1", "Iniciativa 2", "Iniciativa 3"],
          legend: {
            show: true,
            floating: true,
            fontSize: "10px",
            position: "left",
            offsetX: 0,
            offsetY: 0,
            labels: {
              useSeriesColors: false,
            },
            markers: {
              size: 0,
            },
            formatter: function (seriesName, opts) {
              return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%";
            },
            itemMargin: {
              vertical: 3,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          stroke: {
            lineCap: "round",
          },
        },
      },
      usersQuantity4: {
        series: [],
        options2: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          colors: ["#B2B2B2", "#7F7F7F"],
          labels: ["Iniciativa 1", "Iniciativa 2", "Iniciativa 3"],
          legend: {
            show: true,
            floating: true,
            fontSize: "10px",
            position: "left",
            offsetX: 0,
            offsetY: 0,
            labels: {
              useSeriesColors: true,
            },
            markers: {
              size: 0,
            },
            formatter: function (seriesName, opts) {
              return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%";
            },
            itemMargin: {
              vertical: 3,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
        options: {
          series: [76, 100, 61, 40],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: true,
                },
                value: {
                  show: true,
                },
              },
            },
          },
          // Verde Claro:'#16C62E' - Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Gris: '#7F7F7F'
          colors: ["#7F7F7F", "#7F7F7F80", "#7F7F7F60", "#7F7F7F40", "#7F7F7F20"],
          labels: ["Iniciativa 1", "Iniciativa 2", "Iniciativa 3"],
          legend: {
            show: true,
            floating: true,
            fontSize: "10px",
            position: "left",
            offsetX: 80,
            offsetY: 0,
            labels: {
              useSeriesColors: false,
            },
            markers: {
              size: 0,
            },
            formatter: function (seriesName, opts) {
              return seriesName + ": " + opts.w.globals.series[opts.seriesIndex] + "%";
            },
            itemMargin: {
              vertical: 3,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  show: false,
                },
              },
            },
          ],
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await ObjectivesService.getSummaryObjectives(this.project)
        .then((data) => {
          this.summaryObjectives.series = data;
        })
        .catch((err) => console.dir(err));

      await ObjectivesService.chartCircleData(this.project)
        .then((data) => {
          this.dataQuantity = data;
          this.cants = [0, 1, 2];
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
    this.reloadCharts();
  },
};
</script>

<style scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}

.resumen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 235px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  padding: 85px 20px 55px 20px;
}

.resumen > .resumen-detail {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-bar {
  display: flex;
  width: 100%;
  height: fit-content;
  padding-bottom: 15px;
}
.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}
.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  position: relative;
  width: 100%;
  display: flex;
  max-width: 1400px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  justify-content: center;
  margin: 25px 0px;
  padding-bottom: 10px !important;
}
.seguimiento .seguimiento-text {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-wrap: nowrap;
  margin: 30px 0px 0px 20px;
  padding: auto;
  font-size: 14px !important;
  padding: 0px !important;
}

.seguimiento .seguimiento-text div {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px !important;
}
.seguimiento-costo {
  width: 19%;
  margin: 2px;
}
.seguimiento-periodo {
  width: 45%;
  margin: 35px 2px 2px 2px;
}
.list-top-10 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 950px;
  padding: 50px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin: 25px 0px 25px 0px;
}
.list-top-10 > .list-top-10-title {
  font-size: 25px;
}
.list-top-10 > .list-top-10-subtitle {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 15px;
  justify-content: space-between;
  margin: 0px 10px 10px 10px;
  border-bottom: 1px solid black;
  text-align: center;
}
.list-top-10 > .list-top-10-content {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
}
.risk_classification {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
.risk {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.concurrence {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.impact {
  width: 15%;
  margin: 0px 5px 0px 5px;
}
.strategy {
  width: 10%;
  margin: 0px 5px 0px 5px;
}
.contingency {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
.responsible {
  width: 20%;
  margin: 0px 5px 0px 5px;
}
.full {
  width: 100%;
  height: fit-content;
}
.graphic-align {
  text-align: center !important;
}
</style>
